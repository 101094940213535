<!--
 * @Description:欠费订单详情 lackOrderDetail
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-07-22 13:56:33
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <van-nav-bar title="欠费订单详情"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-row class="main">
      <van-row class="infoBox">
        <van-row class="infoTitle">
          订单详情
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">车牌号</van-col>
          <van-col class="details">{{orderList.plateNumber}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">在停地</van-col>
          <van-col class="details">{{orderList.parkName}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">泊位号</van-col>
          <van-col class="details">{{orderList.parkSpaceNumber}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">停车时长</van-col>
          <van-col class="details">{{orderList.parkDuration}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">开始时间</van-col>
          <van-col class="details">{{orderList.entranceTime}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">计费规则</van-col>
          <van-col class="details"
                   style="color:blue"
                   @click="queryBillingRuleDetail">查看计费规则详情</van-col>
        </van-row>
        <van-row class="infoLine"
                 style="margin-bottom:8px">
          <van-col class="name">应收费用</van-col>
          <van-col class="pay">
            <span style="color:#19A9FC;font-size:20px;">{{orderList.lackOrderMoney}}</span>元
          </van-col>
        </van-row>
      </van-row>
      <van-row class="infoBox">
        <van-row class="infoLine"
                 style="margin-bottom:8px">
          <van-col :span=12
                   class="name">订单有疑虑？</van-col>
          <van-col :span=12
                   class="pay"
                   style="float:right;color:#0382E9"
                   @click="toOrderComplain">
            点击申诉
          </van-col>
        </van-row>
      </van-row>
      <!-- 优惠选择 -->
      <van-row class="discount">
        <van-col :span=17
                 class="discount-left">优惠选择</van-col>
        <van-col :span=7
                 class="discount-right">
          暂无可用
          <img src="@/assets/homePage/back.png"
               alt="">
        </van-col>
      </van-row>
      <!-- 底部支付 -->
      <van-row class="bottom">
        <van-col :span="5"
                 class="bottom-col">
          金额:
        </van-col>
        <van-col :span=4
                 class="bottom-totalMoney">
          {{orderList.lackOrderMoney}}
        </van-col>
        <van-col :span=8>元</van-col>
        <van-col :span="7"
                 class="bottom-next"
                 @click="handleClickPay">
          支 付
        </van-col>
      </van-row>
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      orderList: {}, // 订单详情列表
      container: null // 订单申诉
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.container = this.$refs.container
    this.orderList = this.$route.query.lackOrderItem
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 支付按钮
    handleClickPay () {
      if (this.orderList.lackOrderMoney && this.orderList.orderSequence) {
        this.$router.push({ name: 'choosePayStyle', query: { payMoney: this.orderList.lackOrderMoney, payOrder: this.orderList.orderSequence } })
      }
    },
    // 跳转订单申诉
    toOrderComplain () {
      this.$router.push({ name: 'orderComplain', query: { orderSequence: this.orderList.orderSequence } })
    },
    // 查看计费规则详情
    queryBillingRuleDetail () {
      this.$router.push({ name: 'billRuleDetail', query: { billingRuleDefId: this.billingRuleDefId } })
    },
    // 顶部返回按钮
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
.body {
  width: 100%;
  // height: 100%;
  overflow-x: hidden;
  .main {
    height: 100px;
    .infoBox {
      margin: 12px 3.2%;
      background-color: #ffffff;
      width: 93.6%;
      border-radius: 5px;
      padding-top: 12px;
      .infoTitle {
        margin: 7.9px 0 12px 12px;
        font-size: 15px;
        color: #333;
        font-weight: 500;
        font-family: PingFang-SC;
      }
      .line {
        border: 1px dashed #cfcfcf;
        width: 92.8%;
        margin-left: 12px;
      }
      .infoLine {
        height: 33.5px;
        line-height: 33.5px;
        .name {
          display: block;
          width: 103.5px;
          line-height: 33.5px;
          font-size: 15px;
          color: #909090;
          font-weight: 400;
          margin-left: 12px;
        }
        .details {
          display: block;
          width: 100%px;
          line-height: 33.5px;
          font-size: 15px;
          color: #333333;
        }
        .pay {
          display: block;
          width: 80px;
          line-height: 33.5px;
          font-size: 15px;
          color: #333333;
        }
      }
    }
  }
}
.lackOrderDiv {
  width: 93.6%;
  margin-left: 3.2%;
  .lackOrderDiv-title {
    width: 100%;
    height: 26px;
    line-height: 26px;
    font-size: 17px;
    color: #333333;
    margin-bottom: 10px;
  }
  .lackOrderDiv-content {
    width: 100%;
    height: 140px;
    background: #ffffff;
    margin-bottom: 50px;
    .lackOrderDiv-content-lineOne {
      width: 100%;
      height: 40px;
      line-height: 40px;
      padding-left: 10px;
    }
    .lackOrderDiv-content-lineTwo {
      width: 100%;
      height: 1px;
      background: #e7e8e8;
    }
    .lackOrderDiv-content-lineThree {
      padding-left: 10px;
      .lackOrderDiv-content-lineThree-left {
        .lackOrderDiv-content-lineThree-left-lineOne {
          height: 22px;
          line-height: 22px;
          color: #666666;
          font-size: 12px;
        }
        .lackOrderDiv-content-lineThree-left-lineTwo {
          height: 24px;
          line-height: 24px;
          color: #333333;
          font-size: 15px;
        }
        .lackOrderDiv-content-lineThree-left-lineThree {
          height: 22px;
          line-height: 22px;
          color: #666666;
          font-size: 12px;
        }
        .lackOrderDiv-content-lineThree-left-lineFour {
          height: 24px;
          line-height: 24px;
          color: #333333;
          font-size: 15px;
        }
      }
      .lackOrderDiv-content-lineThree-right {
        .lackOrderDiv-content-lineThree-right-lineOne {
          height: 22px;
          line-height: 22px;
          color: #666666;
          font-size: 12px;
        }
        .lackOrderDiv-content-lineThree-right-lineTwo {
          height: 24px;
          line-height: 24px;
          color: #f56060;
          font-size: 15px;
        }
        .lackOrderDiv-content-lineThree-right-lineThree {
          height: 22px;
          line-height: 22px;
          color: #666666;
          font-size: 12px;
        }
        .lackOrderDiv-content-lineThree-right-lineFour {
          height: 24px;
          line-height: 24px;
          color: #333333;
          font-size: 15px;
        }
      }
    }
  }
}
.discount {
  width: 100%;
  height: 46px;
  line-height: 46px;
  background: #ffffff;
  position: absolute;
  bottom: 46px;
  padding-left: 3.2%;
  .discount-left {
  }
  .discount-right {
  }
  .discount-icon {
  }
}
.bottom {
  .discount {
    position: absolute;
    bottom: 46px;
    .discount-left {
    }
    .discount-right {
    }
    .discount-icon {
    }
  }
  width: 100%;
  height: 46px;
  line-height: 46px;
  background-color: white;
  position: absolute;
  bottom: 0;
  .bottom-col {
    padding-left: 20px;
    .col-checkBox {
      margin: 12px 0 0 12px;
      font-size: 15px;
      color: #333333;
    }
  }
  .bottom-totalMoney {
    font-size: 19px;
    color: #19a9fc;
  }
  .bottom-totalMoneyYuan {
    font-size: 15px;
    color: #333333;
  }
  .bottom-next {
    font-size: 17px;
    color: #ffffff;
    background: #19a9fc;
    text-align: center;
  }
}
.container {
  height: 100px;
  width: 40px;
  position: absolute;
  z-index: 1;
  left: 86%;
  top: 82px;
}
</style>
